class RewardsPopup extends HTMLElement {
  constructor() {
    super();

    this.toggles = this.querySelectorAll('.rewards-toggle');
    this.module = this.querySelector('#reward-modal');
    this.chevron = this.querySelector('.rewards-chevron');

    console.log(this.toggles);

    this.toggles.forEach((toggle) => {
      toggle.addEventListener('click', this.toggle.bind(this));
    });
  }

  toggle() {
    this.module.classList.toggle('hidden');
    this.chevron.classList.toggle('rotate-180');
  }
}

customElements.define('rewards-popup', RewardsPopup);
